import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useAuth,
  useUser
} from "reactfire"
import { signOut } from "firebase/auth";
import { useOutsideClick } from "../../hooks/useOutsideClick";


function MainNav() {
  const auth = useAuth()

  const logout = async () => {
    await signOut(auth)
  }

  const [bgOpacity, setBgOpacity] = useState<string>("bg-my-orange/0");

  const {pathname} = useLocation()
  useEffect(() => {
    setBgOpacity("bg-my-orange/0")
    setShowNavSmall(false)
  }, [pathname, setBgOpacity])

  useEffect(() => {

    const handleScroll = () => {
      const base = 100

      if (window.scrollY < base) {
        const opacity = Math.round(window.scrollY / 10) * 10
        setBgOpacity(`bg-my-orange/${opacity}`)
      } else {
        setBgOpacity(`bg-my-orange/${base} backdrop-blur-sm`)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const [showNavSmall, setShowNavSmall] = useState<boolean>(false)

  const toggleNav = () => {
    setShowNavSmall(!showNavSmall)
  }

  // const linkClass = "block align-middle h-full p-4 md:p-8 hover:text-my-olive hover:bg-white/70"

  const { status: userStatus, data: userData } = useUser()
  const [loggedIn, setLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    // While the state is changing, don't do anything
    if (userStatus === "loading" || userStatus === "error") return

    if (userData === null) return setLoggedIn(false)

    // If the user is not anon, set as logged in
    if (userData.isAnonymous === false) return setLoggedIn(true)
  }, [userStatus, userData])

  /*
  const listItemClass = "flex-1"
  const linkClass = "inline-block w-full h-14 flex items-center justify-center bg-red-100 hover:bg-blue-400"
  return (
    <nav className="text-center">
      <ul className="flex">
        <li className={listItemClass}>
          <a href="#" className={linkClass}>Home</a>
        </li>
        <li className={listItemClass}>
          <a href="#" className={linkClass}>Home</a>
        </li>
        <li className={listItemClass}>
          <a href="#" className={linkClass}>Home</a>
        </li>
        <li className={listItemClass}>
          <a href="#" className={linkClass}>Home</a>
        </li>
        <li className={listItemClass}>
          <a href="#" className={linkClass}>Home Home Home Home Home</a>
        </li>
      </ul>
    </nav>
  )
  */

  const ref = useOutsideClick(() => {
    if (showNavSmall === true) return setShowNavSmall(false)
  })

  const listItemClass = "border-solid border-b-2 border-w md:border-none md:flex-1 last:border-none"
  const linkClass = "p-4 md:p-0 inline-block w-full h-16 md:h-22 flex items-center justify-end md:justify-center hover:text-my-olive hover:bg-white/70"

  return (
    <div className={`navbar ${showNavSmall? "bg-my-orange/70 backdrop-blur-sm" : bgOpacity} fixed top-0 drop-shadow-lg font-poppins z-50 w-full`}>
      <div className="max-w-screen-lg mx-auto grid grid-cols-10">
        <div className="logo col-span-5 md:col-span-2 h-16 text-left ">
          <h1 className="h-full m-3 bg-nav-logo-white bg-no-repeat bg-left bg-contain">
            <Link to="/" className="block h-full">
              <span className="invisible h-full">
                Flossi Pay - Collecting money online made easy!
              </span>
            </Link>
          </h1>
        </div>
        <div className="burger col-span-5 md:hidden justify-end flex" onClick={toggleNav}>
          <div className="burger-icon space-y-2 px-6 py-7">
            <span className="block w-8 h-1 bg-white"></span>
            <span className="block w-8 h-1 bg-white"></span>
            <span className="block w-8 h-1 bg-white"></span>
          </div>
        </div>
        <nav
          className={`menu ${showNavSmall ? "": "hidden"} col-span-12 pb-6 md:pb-0 md:col-span-8 text-right md:text-center uppercase md:block md:h-full`}
          ref={ref}
        >
          <ul className="md:flex md:justify-end px-6 md:px-0">
            <li className={listItemClass}>
              <Link to="/" className={`${linkClass}`}>Home</Link>
            </li>
            <li className={listItemClass}>
              <Link to="/create-jar" className={linkClass}>Create Jar</Link>
            </li>
            <li className={listItemClass}>
              <Link to="/jars" className={linkClass}>My Jars</Link>
            </li>
            <li className={listItemClass}>
              <Link to="/my-account" className={linkClass}>My Account</Link>
            </li>
            <li className={listItemClass}>
              {loggedIn === true ?
                <Link to="/" onClick={logout} className={linkClass}>Logout</Link>
              :
                <Link to="/my-account" className={linkClass}>Login</Link>
              }
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default MainNav