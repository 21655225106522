import { Event, Gift } from "../../FirestoreConverters";
import { Link } from "react-router-dom";
import { QueryDocumentSnapshot } from "firebase/firestore";
import Loading from "../General/Loading";
import GeneralContributionCard from "./GeneralContributionCard";
import GiftingContributionCard from "./GiftingContributionCard";
import { ReactComponent as Chevron } from "../Icons/chevron-up.svg"
import { useState } from "react";

interface GiftListProps {
  event: Event;
  contributions: QueryDocumentSnapshot<Gift>[] | undefined;
  showMessages?: boolean;
  showAmounts?: boolean;
  setBillToRepeat: (bill: Gift) => void;
}

const PaidPaymentsList = ({event, contributions, showAmounts, showMessages, setBillToRepeat}: GiftListProps) => {
  const [isOpen, setIsOpen] = useState(false)
  // Loading the data
  if (contributions === undefined) return <div className="p-4"><Loading /></div>

  // Something went wrong
  // if (status === "error") return <p className="p-2">Sorry, we couldn't load contributions right now. Please try again later.</p>

  // Open event, but no contributions
  if (contributions.length === 0 && !event.hasEnded) return <>
    <p className="p-2 text-center">No contributions yet.</p>
    <p className="p-2 text-center">Remember to share your jar via the QR Code or link.</p>
  </>

  // Closed event, but no contributions
  if (contributions.length === 0 && event.hasEnded) return <>
    <p className="p-2 text-center">
      Your jar had no contributions. Why not <Link to="/create-jar" className="font-bold underline">create a new one?</Link>
    </p>
  </>

  return <div>
    <div
      className="uppercase text-xl px-2 py-4 text-white bg-my-olive font-bold flex cursor-pointer"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      {/* <Chevron className={`inline size-6 my-auto transition-all duration-500 ${isOpen ? "rotate-0" : "rotate-180" }`} /> */}
      <Chevron className={`inline size-6 my-auto ${isOpen ? "rotate-180" : "rotate-90" }`} />
      <h3 className="flex-grow my-auto">
        <span>
          Contributions
        </span>
      </h3>
      <div className="text-xs md:text-lg flex-shrink my-auto text-right">
        <p className="md:inline md:mr-2">Total:</p>
        <p className="md:inline">&pound;{showAmounts === true || event.isGifting === false ? event.totalAmount.toFixed(2) : "??.??"}</p>
      </div>
    </div>

    {contributions.map((row) => {
      const gift = row.data()
      return <div className={`border-b last:border-b-0 ${isOpen ? "" : "hidden"}`} key={gift.id}>
        {event.isGifting === true ?
          <GiftingContributionCard contribution={gift} showAmounts={showAmounts} showMessages={showMessages} />
        :
          <GeneralContributionCard contribution={gift} setBillToRepeat={setBillToRepeat} eventHasEnded={event.hasEnded} />
        }
      </div>
    })}
    {/* {giftsData.size >= (giftLimit*parseInt(searchParams.get("page") || "1")) && <div><LoadMoreButton handler={loadMore} /></div>} */}
  </div>
}

export default PaidPaymentsList

// interface LMProps {
//   handler: () => void
// }
// const LoadMoreButton = ({handler}: LMProps) => {
//   return (<>
//     <div className="text-center py-8">
//       <LinkButton
//         type="button"
//         clickHandler={handler}
//       >
//         Load More
//       </LinkButton>
//     </div>
//   </>)
// }