import { useFirestore, useFunctions } from "reactfire";
import { BillContribution, BillTypes, Event, Gift, PaymentStatus, User } from "../../FirestoreConverters";
import { QueryDocumentSnapshot } from "firebase/firestore";
import Loading from "../General/Loading";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Modal from "../General/Modal";
import PendingContributionCard from "./PendingContributionCard";
import LinkButton from "../General/LinkButton";
import { saveGift } from "../../services/Gift";
import { ReactComponent as Chevron } from "../Icons/chevron-up.svg"

interface GiftListProps {
  event: Event;
  contributions: QueryDocumentSnapshot<Gift>[] | undefined;
  userInfo: User;
  setQrToShow: (txn: Gift) => void;
}

const PendingPaymentList = ({event, contributions, setQrToShow, userInfo}: GiftListProps) => {

  const firestore = useFirestore()
  const functions = useFunctions()
  const sendBillEmail = httpsCallable(functions, "sendBillEmail")
  const deleteBill = httpsCallable(functions, "deleteBill")

  const [recreatingBill, setRecreatingBill] = useState(false)
  const [recreatingBillError, setRecreatingBillError] = useState(false)
  const recreateBill = async (bill: BillContribution) => {
    setRecreatingBill(true)
    bill.status = PaymentStatus.PENDING
    const {
      type,
      billType,
      giftAmount,
      feeAmount,
      totalAmount,
      uid,
      giftedAt,
      message,
      email,
      cardholderFName,
      cardholderLName
    } = bill

    const newData = {
      type,
      billType,
      giftAmount,
      feeAmount,
      totalAmount,
      uid,
      giftedAt,
      message,
    } as Gift

    if (billType === BillTypes.EMAIL) {
      newData.cardholderFName = cardholderFName
      newData.cardholderLName = cardholderLName
      newData.email = email
    }

    try {
      await deleteBill({
        jarID: event.id,
        contributionID: bill.id}
      )
      const newBill = await saveGift(firestore, functions, event, newData)

      if (billType === BillTypes.EMAIL) {
        await sendBillEmail({
          jarOwnerFirstName: userInfo.firstName,
          jarOwnerLastName: userInfo.lastName,
          firstName: cardholderFName,
          email: email,
          billUrl: `/contribute-to/${event.id}/bill/${newBill.id}`,
          billAmount: giftAmount,
          feeAmount: feeAmount,
          totalAmount: totalAmount,
          reference: message,
          feeIncluded: event.feeIncluded
        })
      }
      setRecreatingBill(false)
    } catch (e) {
      setRecreatingBillError(true)
    }
  }

  // What are we operating on?
  const [billToDelete, setBillToDelete] = useState<Gift | false>(false)
  // Was there a problem operating on it?
  const [billDeleteError, setBillDeleteError] = useState<string | false>(false)
  // Is the operation in progress?
  const [billDeleting, setBillDeleting] = useState<boolean>(false)
  // Is the operation complete?
  const [billDeleted, setBillDeleted] = useState<boolean>(false)

  const [sendingReminder, setSendingReminder] = useState<boolean>(false)
  const [reminderSent, setReminderSent] = useState<boolean>(false)
  const [sendingError, setSendingError] = useState<boolean>(false)
  const sendBillReminder = async (bill: Gift) => {
    setSendingReminder(true)
    try {
      await sendBillEmail({
        jarOwnerFirstName: userInfo.firstName,
        jarOwnerLastName: userInfo.lastName,
        firstName: bill.cardholderFName,
        email: bill.email,
        billUrl: `/contribute-to/${event.id}/bill/${bill.id}`,
        billAmount: bill.giftAmount,
        feeAmount: bill.feeAmount,
        totalAmount: bill.totalAmount,
        reference: bill.message,
        feeIncluded: event.feeIncluded
      })
      setReminderSent(true)
      setSendingReminder(false)
    } catch (e) {
      setSendingError(true)
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  // Open or closed event, with no pendings
  if (contributions === undefined || contributions.length === 0) return null

  const totalAmount: number = contributions.reduce((total, doc) => {
    return total + doc.data().giftAmount
  }, 0)

  return <>
    {sendingReminder === true || reminderSent === true?
      <Modal title="Send bill reminder" closeFunction={(() => {
        return reminderSent === true ? () => {
          setSendingReminder(false)
          setReminderSent(false)
          setSendingError(false)
        } : undefined;
      })()}>
        {sendingError === false ?
          reminderSent === false ?
            <Loading text="Sending..."/>
          :
            <>
              <div className="mx-20">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-bottom text-my-olive">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </div>
              <p className="text-center">Reminder has been sent</p>
            </>
        :
          <div className="text-center py-10">
            <p>There was an error sending the reminder.</p>
            <button
              onClick={() => {
                setSendingReminder(false)
                setReminderSent(false)
                setSendingError(false)
              }}
              className="my-2 text-my-orange underline"
            >
              Please close and try again
            </button>
          </div>
        }
      </Modal>
    : null}

    {billToDelete !== false ?
      <Modal title="Delete bill?" closeFunction={() => {
        setBillToDelete(false)
        setBillDeleteError(false)
        setBillDeleting(false)
        setBillDeleted(false)
      }}>
        <div className="text-center">
          {billDeleting === true ?
            <Loading text="Deleting bill..."/>
          : null }

          {billDeleted === true ?
            <>
              <div className="mx-20">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-bottom text-my-olive">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </div>
              <p className="text-center">Bill deleted. You can now close this popup</p>
            </>
          : null }

          {billDeleteError !== false && billDeleting === false ?
            <p className="bg-red-200 text-red-600 border border-red-600 p-4 m-4">
              {billDeleteError}
            </p>
          : null }

          {billDeleting === false && billDeleted === false ?
            <>
              <p className="py-4">Are you sure you want to delete this bill?</p>

              <LinkButton type="button" clickHandler={async () => {
                try {
                  setBillDeleteError(false)
                  setBillDeleting(true)
                  await deleteBill({
                    jarID: event.id,
                    contributionID: billToDelete.id
                  })
                  setBillDeleted(true)
                } catch (e) {
                  setBillDeleteError("Failed to delete bill, please try again")
                }
                setBillDeleting(false)
              }}>
                Confirm
              </LinkButton>
            </>
          : null }
        </div>
      </Modal>
    : null}

    {recreatingBill === true ?
      <Modal title="Recreate Bill" closeFunction={() => {
        setRecreatingBill(false)
        setRecreatingBillError(false)
      }}>
        {recreatingBillError === false ?
          <Loading text="Recreating bill..." />
        :
          <div className="text-center">
            <p className="bg-red-200 text-red-600 border border-red-600 p-4 m-4">
              Failed to recreate the bill
            </p>
            <p>Please close this popup try again</p>
            {/* <LinkButton type="button" clickHandler={() => {
              setRecreatingBill(false)
              setRecreatingBillError(false)
            }}>

            </LinkButton> */}
          </div>
        }
      </Modal>
    : null}

    <div
      className="uppercase text-xl px-2 py-4 text-white bg-my-olive font-bold flex cursor-pointer"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      {/* <Chevron className={`inline size-6 my-auto transition-all duration-500 ${isOpen ? "rotate-0" : "rotate-180" }`} /> */}
      <Chevron className={`inline size-6 my-auto ${isOpen ? "rotate-180" : "rotate-90" }`} />
      <h3 className="flex-grow my-auto">
        <span>
          Unpaid Bills
        </span>
      </h3>
      <div className="text-xs md:text-lg flex-shrink my-auto text-right">
        <p className="md:inline md:mr-2">Outstanding:</p>
        <p className="md:inline">£{totalAmount.toFixed(2)}</p>
      </div>
    </div>
    {/* <div className={`${isOpen ? "" : "h-0 overflow-hidden"}`}> */}
    <div className={`${isOpen ? "" : "hidden"}`}>
      {contributions.map((row) => {
        const contribution = row.data()
        return <PendingContributionCard
          contribution={contribution}
          event={event}
          setQrToShow={setQrToShow}
          sendBillReminder={sendBillReminder}
          setBillToDelete={setBillToDelete}
          recreateBill={recreateBill}
        />
      })}
    </div>
    {/* {giftsData.size >= (giftLimit*parseInt(searchParams.get("page") || "1")) && <div><LoadMoreButton handler={loadMore} /></div>} */}
  </>
}

export default PendingPaymentList