import {
  Outlet,
  useLocation,
  useParams
} from "react-router-dom";
import {
  doc,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot
} from 'firebase/firestore';
import {
  useAuth,
  useFirestore,
  useSigninCheck,
  useStorage
} from 'reactfire';

import {
  useEffect,
  useState } from "react";

import NotFound from "../General/NotFound";
import Loading from "../General/Loading";
import { CustomJarHeaderData, Event/*, User as Organiser*/, FirestoreEventConverter,/*, FirestoreUserConverter*/
JarStatus} from "../../FirestoreConverters";
import { Auth, User, signInAnonymously } from "firebase/auth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Hero from "../General/Hero";
import JarTitleBanner from "../Event/JarTitleBanner";
import CustomJarHeader from "../Event/CustomJarHeader";
import { getDownloadURL, ref, StorageReference } from "firebase/storage";

export default function GiftToEvent () {

  const [, setDocumentTitle] = useDocumentTitle()

  const firestore = useFirestore()
  const {eventName} = useParams()
  const [eventData, setEventData] = useState<Event | undefined | false>(undefined)
  //const [organiserData, setOrganiserData] = useState<Organiser | undefined | false>(undefined)
  const [user, setUser] = useState<false | User>(false)

  const auth = useAuth()
  const {status: signInStatus, data: signInCheckResult} = useSigninCheck()

  useEffect(() => {
    const loginAnon = async (auth: Auth) => {
      const u = await signInAnonymously(auth)
      return u
    }

    if (signInStatus === "loading") return;
    if (signInStatus === 'success' && signInCheckResult.signedIn === false) {
      loginAnon(auth)
    }

    if (signInStatus === 'success' && signInCheckResult.signedIn === true) {
      setUser(signInCheckResult.user)
    }
  }, [auth, signInStatus, signInCheckResult])

  /**
   * Get the event information using the URL slug
   **/
  useEffect(() => {
    if (user === false || eventName === undefined) {
      return
    }
    const streamEvent = (eventId: string | undefined, snapshotHandler: (snapshot: DocumentSnapshot<Event>) => void, errorHandler: (e: FirestoreError) => void) => {
      if (eventId === undefined) return
      const eventRef = doc(firestore, `events/${eventId}`)
        .withConverter(FirestoreEventConverter);
      return onSnapshot(eventRef, snapshotHandler, errorHandler)
    }
    const unsub = streamEvent(eventName,
      (snapshot) => {
        const event = snapshot.data()
        if (event === undefined) {
          return setEventData(false)
        }
        setEventData(event)
        setDocumentTitle(`Contribute to ${event.name}`)
      },
      (e) => {
        console.log(e)
        setEventData(false)
      }
    )
    return unsub
  }, [eventName, firestore, user, setDocumentTitle])

  const storage = useStorage()
  const [bgData, setBgData] = useState<CustomJarHeaderData>()
  useEffect(() => {
    const getUrl = (ref: StorageReference) => {
      getDownloadURL(ref)
        .then((url) => {
          let yOffset = 0
          if (eventData !== false && eventData !== undefined && eventData.backgroundImage !== undefined ) {
            yOffset = eventData.backgroundImage.yOffset
          }

          setBgData({
            filename: url,
            yOffset
          })
        })
    }

    if (
      eventData === false
      || eventData === undefined
      || eventData.backgroundImage === undefined
    ) return

    const bgRef = ref(storage, eventData.backgroundImage.filename)
    getUrl(bgRef)
  }, [eventData, storage])

  const lastUrlPart = useLocation().pathname.split("/").at(-1)

  let content = <Loading />;
  let title = "Loading...";
  if (eventData !== false && eventData !== undefined && user !== false && eventData.hasEnded === false && eventData.status === JarStatus.OPEN) {
    content = <Outlet context={{eventInfo: eventData, userInfo: user}} />
    title = eventData.name
  } else if (eventData !== false && eventData !== undefined && (eventData.hasEnded === true || eventData.status !== JarStatus.OPEN)) {
    content = <>
      <p className="text-my-grey font-bold pt-4 text-xl uppercase">This jar is now closed!</p>
      <p className="py-4 font-beloved">{eventData.message}</p>
    </>
    title = eventData.name
  } else if (eventData === false) {
    content = <NotFound />
    title = "Oops!"
  }

  return (
    <div className="bg-white">
      <div className={`relative ${bgData !== undefined ? "pt-22 bg-my-orange max-w-screen-lg mx-auto w-full" : ""}`}>
        {bgData !== undefined ?
          <CustomJarHeader image={bgData} />
        :
          <Hero title={eventData === false ? "Oops.." : ""} height="h-48 md:h-60" />
        }
        <JarTitleBanner
          name={title}
          date={eventData !== undefined && eventData !== false ? eventData.date : null}
          width={bgData !== undefined ? "max-w-screen-lg" : undefined}
        />
      </div>

      {eventData !== undefined && eventData !== false && lastUrlPart !== "confirmation" ?
        <p className="p-2 max-w-lg break-words font-poppins text-sm md:w-3/4 mx-auto">
          {eventData.message}
        </p>
      : null}

      <div className="mx-auto max-w-screen-lg text-my-grey px-4 py-2">
        {content}
      </div>
    </div>
  )
}