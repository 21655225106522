import LinkButton from "../General/LinkButton"
import { useState } from "react"
import JarTypeModal from "./JarTypeModal"
import Hero from "../General/Hero"

const TypeSelect = () => {

  const [showJarTypeInfoModal, setShowJarTypeInfoModal] = useState<boolean>(false)

  return <>
    {showJarTypeInfoModal === true ?
      <JarTypeModal onClose={() => setShowJarTypeInfoModal(false)}/>
    : null }

    <Hero title="Create a Jar" sub="What are you collecting for?" />

    <div className="max-w-screen-lg mx-auto w-full p-4 flex-grow flex flex-col">
      <div className="text-center my-auto">
        <LinkButton type="link" url="gift" backgroundColour="my-olive">Gifting Collection</LinkButton>
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-my-olive"></div>
          <span className="flex-shrink mx-4 text-my-olive">Or</span>
          <div className="flex-grow border-t border-my-olive"></div>
        </div>
        <LinkButton type="link" url="general" backgroundColour="my-olive">General Collection</LinkButton>

        <div>
          <button type="button" onClick={() => setShowJarTypeInfoModal(true)} className="underline py-4">
            Which jar type am I collecting for?
          </button>
        </div>
      </div>
    </div>
  </>
}

export default TypeSelect