import { useState } from "react"
import { Event, JarStatus } from "../../FirestoreConverters"
import LinkButton from "../General/LinkButton"
import QRCode from "./QRCode"
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { ReactComponent as Chevron } from "../Icons/chevron-up.svg"

const ShareJar = ({eventData}: {eventData: Event}) => {

  const eventUrl = `http://${window.location.host}/contribute-to/${eventData.id}`

  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)
  const copyLink = async () => {
    if (!eventUrl) return
    await navigator.clipboard.writeText(`${eventUrl}?utm_source=jaradm&utm_medium=link`)
    setCopiedToClipboard(true)
    setTimeout(() => {
      setCopiedToClipboard(false)
    }, 5000)
  }

  const [isOpen, setIsOpen] = useState(true)

  if (eventData.hasEnded === true || eventData.status !== JarStatus.OPEN) {
    return null
  }

  return <>
    <div
      className="uppercase text-xl px-2 py-4 text-white bg-my-olive font-bold flex cursor-pointer"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      {/* <Chevron className={`inline size-6 my-auto transition-all duration-500 ${isOpen ? "rotate-0" : "rotate-180" }`} /> */}
      <Chevron className={`inline size-6 my-auto ${isOpen ? "rotate-180" : "rotate-90" }`} />
      <h3 className="flex-grow my-auto">
        <span>
          Share
        </span>
      </h3>
    </div>

    <div className={`${isOpen ? "" : "hidden"} text-center space-y-1 px-2 pt-1`}>
      <LinkButton
        type="button"
        clickHandler={copyLink}
      >
        {!copiedToClipboard ? "Copy jar link" : "Link copied!"}
      </LinkButton>

      <div>
        <QRCode
          eventUrl={`${eventUrl}?utm_source=jaradm&utm_medium=qrcode`}
          eventName={eventData.name}
          showDownloadButton={true}
        />
      </div>
      <div>
        <FacebookShareButton
          url={`${eventUrl}?utm_source=jaradm&utm_medium=facebook`}
        >
          <img
            src="/img/socials/fb-olive.png"
            alt={`Share ${eventData.name} on Facebook`}
            className="w-10 mx-1"
          />
        </FacebookShareButton>
        <WhatsappShareButton
          url={`${eventUrl}?utm_source=jaradm&utm_medium=whatsapp`}
          title={`Contribute to ${eventData.name}`}
        >
          <img
            src="/img/socials/wa-olive.png"
            alt={`Share ${eventData.name} to WhatsApp`}
            className="w-10 mx-1"
          />
        </WhatsappShareButton>
        <TwitterShareButton
          url={`${eventUrl}?utm_source=jaradm&utm_medium=x`}
        >
          <img
            src="/img/socials/x-olive.png"
            alt={`Share ${eventData.name} to X/Twitter`}
            className="w-10 mx-1"
          />
        </TwitterShareButton>
        <EmailShareButton
          url={`${eventUrl}?utm_source=jaradm&utm_medium=email`}
        >
          <img
            src="/img/email-white-icon.png"
            alt={`Share ${eventData.name} via Email`}
            className="w-10 mx-1 bg-my-olive bg-clip rounded-xl"
          />
        </EmailShareButton>
      </div>
    </div>
  </>
}

export default ShareJar