import { useEffect, useState } from "react"
import { CustomJarHeaderData } from "../../FirestoreConverters"


const CustomJarHeader = ({image: {filename, yOffset}}: {image: CustomJarHeaderData}) => {

  const [bgCss, setBgCss] = useState({})

  useEffect(() => {
    setBgCss({
      '--image': `url(${filename})`,
      'backgroundPositionY': `${yOffset}%`,
      'backgroundPositionX': 'center',
      'backgroundSize': 'cover',
    })
  }, [filename, yOffset])

  return <div
    className={`w-full max-w-screen-lg mx-auto aspect-header bg-[image:var(--image)] bg-no-repeat bg-cover`}
    style={bgCss}
  />
}

export default CustomJarHeader