import { ChangeEvent, useEffect, useRef, useState } from "react"
import LinkButton from "../General/LinkButton"
import { CustomJarHeaderData, Event, FirestoreEventConverter } from "../../FirestoreConverters"
import { useFirestore, useStorage } from "reactfire";
import { getDownloadURL, ref, StorageReference, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import CustomJarHeader from "../Event/CustomJarHeader";

type Props = {
  event: Event;
}

const ManageJarBackgroundImage = ({event}: Props) => {

  const storage = useStorage()
  const firestore = useFirestore()

  const [fileData, setFileData] = useState<File>()
  const [bgData, setBgData] = useState<CustomJarHeaderData>()
  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const {target} = e

    if (target.files === null || target.files.length <= 0) return

    const fileBase = target.files[0]
    const filename = URL.createObjectURL(fileBase)
    setBgData({
      filename,
      yOffset: 0
    })
    setFileData(fileBase)
  }

  const [isSaving, setIsSaving] = useState(false)
  const saveImage = async () => {

    if (bgData === undefined) return

    setIsSaving(true)
    let {filename} = bgData
    const {yOffset} = bgData

    // only upload if there's a new file
    if (fileData !== undefined) {
      const extension = fileData.type.split("/")[1]
      filename = `jars/${event.id}/header.${extension}`
      const fileRef = ref(storage, filename)
      await uploadBytes(fileRef, fileData)
    }

    const eventRef = doc(firestore, "events", event.id)
      .withConverter(FirestoreEventConverter)
    const backgroundData = {
      filename,
      yOffset
    }
    await updateDoc(eventRef, {
      backgroundImage: backgroundData
    })

    setIsSaving(false)
  }

  const [isDragging, setIsDragging] = useState(false)
  const handlePointerDown = () => {
    setIsDragging(true)
  }
  const handlePointerUp = () => {
    setIsDragging(false)
  }

  const handlePointerMove = (e: any) => {
    if (
      isDragging !== true
      || imageContainerRef === null
      || imageContainerRef.current === undefined
      || bgData === undefined
    ) return

    const {filename, yOffset} = bgData
    const newOffset = yOffset + ((e.movementY/2)*-1)
    if (newOffset <= 0 || newOffset >= 100) return

    setBgData({
      filename,
      yOffset: newOffset
    })
  }

  const imageContainerRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const getUrl = (ref: StorageReference) => {
      getDownloadURL(ref)
        .then((filename) => {
          let yOffset = 0
          if (event.backgroundImage !== undefined) {
            yOffset = event.backgroundImage.yOffset
          }

          setBgData({
            filename,
            yOffset
          })
        })
    }

    if (
      event === undefined
      || event.backgroundImage === undefined
    ) return

    const bgRef = ref(storage, event.backgroundImage.filename)
    getUrl(bgRef)
  }, [event, storage])

  return <div className="space-y-2 py-2">
    {bgData !== undefined ?
      <>
        <div
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerUp}
          onPointerMove={handlePointerMove}
          className="touch-none cursor-move relative"
          ref={imageContainerRef}
        >
          {isDragging === false ?
            <div className="absolute w-full h-full flex items-center justify-center">
              <p className="text-xs text-white bg-my-olive bg-opacity-60 p-1 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-4 inline-block">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                </svg>
                Drag to move
              </p>
            </div>
          : null}
          <CustomJarHeader image={bgData} />
        </div>
      </>
    :
      <div className="py-10">
        Select an image to use
      </div>
    }
    <form>
      <fieldset
        disabled={isSaving}
        className={`grid gap-2 grid-cols-1 md:grid-cols-${bgData !== undefined ? "2" : "1"}`}
      >

        {bgData !== undefined ?
          <LinkButton type="button" clickHandler={saveImage}>
            {isSaving === true ?
              "Saving..."
            :
              "Save"
            }
          </LinkButton>
        : null}

        <LinkButton
          clickHandler={() => {
            if(fileInputRef.current !== null) {
              fileInputRef.current.click()
            }
          }}
          type="button"
        >
          {bgData !== undefined ?
            <>
              Change Picture
            </>
          :
            <>
              Select Picture
            </>
          }
        </LinkButton>

        <input
          type="file"
          onChange={handleImageUpload}
          ref={fileInputRef}
          alt="image"
          className="hidden"
          accept="image/*"
        />
      </fieldset>
    </form>
  </div>
}

export default ManageJarBackgroundImage