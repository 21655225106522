const JarTitleBanner = ({name, date, width}: {name: string, date: Date | null, width?: string;}) => {
  return <div className={`bg-my-cream p-4 md:p-8 pb-2 md:pb-6 text-my-grey text-2xl md:text-3xl font-beloved w-full ${width !== undefined ? width : ""}  mx-auto`}>
    <div className={`${width !== undefined ? width : "w-full"} mx-auto`}>
      <p className="lg:w-3/4 mx-auto">
        {name}
      </p>
      {date !== null ?
        <p className="text-sm">
          Closes: {date.toLocaleDateString('en-GB')}
        </p>
      : null}
    </div>
  </div>
}

export default JarTitleBanner