import { useOutletContext } from "react-router-dom"
import { CreateGiftingForm } from "./CreateGiftingForm"
import { CreateGeneralForm } from "./CreateGeneralForm"
import { MyAccountProps } from "../Page/MyAccount"
import LinkButton from "../General/LinkButton"

const EditEvent = () => {
  const {authUser, event} = useOutletContext<MyAccountProps>()

  if (event === undefined) {
    return <div>Could not find jar</div>
  }

  if (event.hasEnded) {
    return <div className="p-4 space-y-2">
      <p>
        This jar has closed and cannot be edited. Why not create a new one?
      </p>
      <LinkButton
        backgroundColour="my-olive"
        type="link"
        url="/create-jar"
      >
        Create a new jar
      </LinkButton>
    </div>
  }

  return (
    <div className="text-left p-4 md:p-0 max-w-screen-md mx-auto w-full">
    {event.isGifting === true ?
      <CreateGiftingForm user={authUser} event={event} />
    :
      <CreateGeneralForm user={authUser} event={event} />
    }
    </div>
  )
}

export default EditEvent