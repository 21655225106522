import {CreateGiftingForm} from "../Event/CreateGiftingForm"
import { CreateGeneralForm } from "../Event/CreateGeneralForm";
import { useOutletContext } from "react-router-dom";
import { MyAccountProps } from "./MyAccount";
import Hero from "../General/Hero";

interface CreateEventProps {
  type: CollectionTypes
}

export enum CollectionTypes {
  GENERAL = "general",
  GIFTING = "gifting"
}

const CreateEvent = ({type}: CreateEventProps) => {

  const {authUser} = useOutletContext<MyAccountProps>()

  return <>
    <Hero title="Create Jar" sub={`${type === CollectionTypes.GIFTING ? "Gifting" : "Collecting"} money online made easy`} />
    <div className="text-left p-4 md:p-0 max-w-screen-md mx-auto w-full">
      {type === CollectionTypes.GIFTING ?
        <CreateGiftingForm user={authUser} />
        :
        <CreateGeneralForm user={authUser} />
    }
    </div>
  </>
}

export default CreateEvent