import {
  collection,
  doc,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Outlet,
  useOutletContext,
  useParams
} from "react-router-dom";
import { useFirestore, useStorage } from "reactfire";
import { CustomJarHeaderData, Event, FirestoreEventConverter, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters";
import LinkButton from "../General/LinkButton";
import Loading from "../General/Loading";
import { MyAccountProps } from "./MyAccount";
import Hero from "../General/Hero";
import JarTitleBanner from "../Event/JarTitleBanner";
import Modal from "../General/Modal";
import ManageJarBackgroundImage from "./ManageJarBackgroundImage";
import { getDownloadURL, ref, StorageReference } from "firebase/storage";
import CustomJarHeader from "../Event/CustomJarHeader";

export default function EventAdmin () {

  const {authUser, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()

  const storage = useStorage()
  const firestore = useFirestore()
  const {eventName} = useParams()

  const [eventData, setEventData] = useState<Event | undefined | false>(undefined)
  useEffect(() => {
    const streamEvent = (eventId: string | undefined, snapshotHandler: (snapshot: DocumentSnapshot<Event>) => void, errorHandler: (e: FirestoreError) => void) => {
      if (eventId === undefined) return
      const eventRef = doc(firestore, `events/${eventId}`)
        .withConverter(FirestoreEventConverter);
      return onSnapshot(eventRef, snapshotHandler, errorHandler)
    }

    const unsub = streamEvent(eventName,
      (snapshot) => {
        const event = snapshot.data()
        if (event === undefined || event.organiser.path !== `users/${authUser.uid}`) {
          setEventData(false)
          return
        }
        setEventData(event)
      },
      (e) => {
        console.log(e)
        setEventData(false)
      }
    )
    return unsub
  }, [eventName, setEventData, firestore, authUser])

  const [contributions, setContributions] = useState<QuerySnapshot<Gift>>()
  useEffect(() => {
    if (eventData === undefined || eventData === false) return

    const streamContributions = (eventId: string, snapshotHandler: (snapshot: QuerySnapshot<Gift>) => void, errorHandler: (e: FirestoreError) => void) => {
      const giftsRef = collection(firestore, `events/${eventId}/gifts`)
        .withConverter(FirestoreGiftConverter)
      const giftsQuery = query(
        giftsRef,
        where("status", "in",
          [
            PaymentStatus.PAID,
            PaymentStatus.PENDING,
            PaymentStatus.FAILED,
            PaymentStatus.CANCELLED
          ]
        ),
        orderBy("giftedAt", "desc"),
      )
      return onSnapshot(giftsQuery, snapshotHandler, errorHandler)
    }

    const unsub = streamContributions(eventData.id,
      (snapshot) => {
        setContributions(snapshot)
      },
      (e) => {
        setContributions(undefined)
      }
    )
    return unsub

  }, [firestore, eventData])

  const [showBgModal, setShowBgModal] = useState(false)
  const [bgData, setBgData] = useState<CustomJarHeaderData>()

  useEffect(() => {
    const getUrl = (ref: StorageReference) => {
      getDownloadURL(ref)
        .then((url) => {
          let yOffset = 0
          if (eventData !== false && eventData !== undefined && eventData.backgroundImage !== undefined ) {
            yOffset = eventData.backgroundImage.yOffset
          }

          setBgData({
            filename: url,
            yOffset
          })
        })
    }

    if (
      eventData === false
      || eventData === undefined
      || eventData.backgroundImage === undefined
    ) return

    const bgRef = ref(storage, eventData.backgroundImage.filename)
    getUrl(bgRef)
  }, [eventData, storage])

  // Undefined: We don't know if the event or gifts exist yet
  // False: Event or gifts could not be found
  if (eventData === undefined) {
    return <Loading />
  }

  return <>
    {eventData !== false && showBgModal === true ?
      <Modal
        title="Change Background"
        closeFunction={() => setShowBgModal(false)}
      >
        <ManageJarBackgroundImage event={eventData} />
      </Modal>
    : null}

    <div className={`relative ${bgData !== undefined ? "pt-22 bg-my-orange max-w-screen-lg mx-auto w-full" : ""}`}>
      {bgData !== undefined ?
        <CustomJarHeader image={bgData} />
      :
        <Hero title={eventData === false ? "Oops.." : ""} height="h-48 md:h-60" />
      }
      <div className="absolute top-0 left-0 right-0 w-full max-w-screen-lg mx-auto h-full text-right reltaive">
        <button
          className="absolute bottom-0 right-0 rounded-lg bg-my-olive hover:bg-my-orange p-2 text-white text-xs bg-opacity-80 hover:bg-opacity-100 scale-75"
          onClick={() => setShowBgModal(true)}
          type="button"
        >

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
          </svg>
          <span className="p-1 hidden md:inline-block">
            Change Picture
          </span>

        </button>
      </div>
    </div>
    {eventData !== false ?
      <>
        <JarTitleBanner
          name={eventData.name}
          date={eventData.date}
          width={bgData !== undefined ? "max-w-screen-lg" : undefined}
        />
        <div className="max-w-screen-lg mx-auto w-full md:px-4">
          <Outlet context={{event: eventData, contributions: contributions, authUser, userInfo, setDocumentTitle, setSubTitle}} />
        </div>
      </>
    :
      <div className="p-4">
        <p className="text-my-grey p-2">We're sorry, but we could'nt find the jar you were looking for.</p>
        <LinkButton type="link" url="/create-jar">Create Jar</LinkButton>
      </div>
    }
  </>
}