import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { ContributionTypes, Event, Gift, JarStatus, PaymentStatus } from "../../FirestoreConverters"
import LinkButton from "../General/LinkButton"
import { MyAccountProps } from "../Page/MyAccount"
import PendingPaymentList from "./PendingPaymentList"
import CreateBillModal from "./CreateBillModal"
import { doc, QueryDocumentSnapshot, QuerySnapshot, updateDoc } from "firebase/firestore"
import DeleteJar from "./DeleteJar"
import CloseJar from "./CloseJar"
import ShareJar from "./ShareJar"
import DisplayBillQR from "./DisplayBillQR"
import PaidPaymentsList from "./PaidPaymentsList"
import Tooltip from "../General/Tooltip"
import { useFirestore } from "reactfire"
import { ShowMore } from "@re-dev/react-truncate"
import { ReactComponent as Chevron } from "../Icons/chevron-up.svg"

const EventAdminInfo = () => {
  const {event: eventData, contributions, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps & {event: Event, contributions: QuerySnapshot<Gift>}>()

  setDocumentTitle(eventData ? eventData.name : "")
  setSubTitle(eventData && eventData.date !== null ? eventData.date.toLocaleDateString() : "Always Open")

  const firestore = useFirestore()

  const [displayAmounts, setDisplayAmounts] = useState<boolean>(false)
  const [openMessage, setOpenMessage] = useState<boolean>(false)

  const [showCreateTxnForm, setShowCreateTxnForm] = useState(false)
  const [billToRepeat, setBillToRepeat] = useState<Gift>()
  const [showBillModalFor, setShowBillModalFor] = useState<Gift | undefined>()

  const [jarToDelete, setJarToDelete] = useState<Event>()
  const [isDeleteable, setIsDeleteable] = useState(false)
  const [jarToClose, setJarToClose] = useState<Event>()
  const [isCloseable, setIsCloseable] = useState(false)

  const [paidPayments, setPaidPayments] = useState<QueryDocumentSnapshot<Gift>[]>()
  const [pendingPayments, setPendingPayments] = useState<QueryDocumentSnapshot<Gift>[]>()
  useEffect(() => {
    if (contributions === undefined) return
    const paid = contributions.docs.filter((row) => {
      return row.data().status === PaymentStatus.PAID
    })
    setPaidPayments(paid)

    const pending = contributions.docs.filter((row) => {
      const {status, paymentJob, type} = row.data()
      return status === PaymentStatus.PENDING
        && type === ContributionTypes.BILL
        && paymentJob !== undefined
    })
    setPendingPayments(pending)

    if (
      // Not gifting
      eventData.isGifting === false &&
      // Not already closed
      eventData.status !== JarStatus.CLOSED &&
      // No pending payments
      pending.length <= 0
    ) {
      setIsCloseable(true)
    } else {
      setIsCloseable(false)
    }

    if (
      // No contributions at all
      contributions.empty === true
    ) {
      setIsDeleteable(true)
    } else {
      setIsDeleteable(false)
    }
  }, [contributions, eventData])

  const [isRedeemable, setIsRedeemable] = useState<boolean>(false)
  useEffect(() => {
    if (
      // Hasn't already been claimed
      eventData.hasBeenClaimed === false
      // Has some contributions
      && eventData.totalAmount > 0
      // Isn't currently being redeemed
      && eventData.redeemInProgress === false
      && (
        // Gifting & has ended
        (eventData.isGifting === true && eventData.hasEnded === true)
        ||
        // Open ended (always redeemable as long as there are funds)
        (eventData.isGifting === false)
      )
    ) {
      setIsRedeemable(true)
    } else {
      setIsRedeemable(false)
    }
  }, [eventData])

  const [error, setError] = useState<string>()
  const reopenJar = async () => {
    const data = {
      date: null,
      status: JarStatus.OPEN,
    }
    const docRef = doc(firestore, "events", eventData.id)
    try {
      // throw new Error("dssdsdf")
      await updateDoc(docRef, data)
      setError(undefined)
    } catch (e) {
      setError("Failed to re-open jar. Please try again")
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  return <>
    {error !== undefined ?
      <div className="border border-red-600 bg-red-50 text-red-600 p-4 col-span-1 md:col-span-3 text-center">{error}</div>
    : null}
    {isDeleteable === true && jarToDelete !== undefined ?
      <DeleteJar jar={jarToDelete} setJarToDelete={setJarToDelete}/>
    : null}

    {isCloseable === true && jarToClose !== undefined ?
      <CloseJar jar={jarToClose} setJarToClose={setJarToClose}/>
    : null}

    {showCreateTxnForm === true ?
      <CreateBillModal event={eventData} userInfo={userInfo} closeFunction={(show: boolean) => {
        setBillToRepeat(undefined)
        setShowCreateTxnForm(show)
      }} setQrToShow={setShowBillModalFor} billToRepeat={billToRepeat} />
    : null}

    {
      showBillModalFor !== undefined
      && showBillModalFor.id !== undefined
    ?
      <DisplayBillQR
        jar={eventData}
        bill={showBillModalFor}
        closeHandler={() => {
          setShowBillModalFor(undefined)
        }}
      />
    : null}

    {eventData.hasEnded ?
      <p className="text-my-grey text-lg p-4 bg-green-200 text-center border-2 md:col-span-3 m-2 md:m-0">
        {eventData.totalAmount > 0 && eventData.hasBeenClaimed ?
          <>Your jar is now closed and has been redeemed</>
        :
          <>Your jar is now closed {eventData.totalAmount > 0 && eventData.redeemInProgress === false ? "and can be redeemed" : null}</>
        }
      </p>
    : null}

    <div className="font-poppins text-sm p-4 md:w-3/4 mx-auto">
      <ShowMore lines={3} more="more" less="less" anchorClass="text-my-olive">
        {eventData.message}
      </ShowMore>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-3 gap-1 text-left">
      <div className="order-1 md:order-2">
        <div
          className="uppercase text-xl px-2 py-4 text-white bg-my-olive font-bold flex cursor-pointer"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {/* <Chevron className={`inline size-6 my-auto transition-all duration-500 ${isOpen ? "rotate-0" : "rotate-180" }`} /> */}
          <Chevron className={`inline size-6 my-auto ${isOpen ? "rotate-180" : "rotate-90" }`} />
          <h3 className="flex-grow my-auto">
            <span>
              Manage
            </span>
          </h3>
        </div>
        <div className={`${isOpen ? "" : "hidden"} text-center grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 space-y-1 px-2 pt-1`}>
          {eventData.isGifting ? <>
            <LinkButton type="button" clickHandler={() => setOpenMessage(!openMessage)}>{!openMessage ? "View Messages" : "Hide Messages"}</LinkButton>
            <LinkButton type="button" clickHandler={() => setDisplayAmounts(!displayAmounts)}>{!displayAmounts ? "View Contributions" : "Hide Contributions"}</LinkButton>
          </>
          : null}

          {eventData.isGifting === false && eventData.hasEnded === false && eventData.status === JarStatus.OPEN  ?
            <LinkButton type="button" clickHandler={() => setShowCreateTxnForm(true)}>Create Bill</LinkButton>
          : null}

          {eventData.hasEnded === false && eventData.status === JarStatus.OPEN ?
            <LinkButton type="link" url="edit">Edit Jar</LinkButton>
          : null}

          {isRedeemable === true ?
            <LinkButton type="link" url="redeem">Redeem Contributions</LinkButton> : null
          }

          <LinkButton
            type="button"
            clickHandler={() => setJarToDelete(eventData)}
            disabled={!isDeleteable}
          >
            {isDeleteable === false ?
              <Tooltip
                text="Jar cannot be deleted as there are either unpaid bills, or it has had successful contributions"
                className="font-normal"
              >
                <span className="font-bold">Delete jar</span>
              </Tooltip>
            :
              <>Delete Jar</>
            }
          </LinkButton>

          {eventData.isGifting === false && eventData.status !== JarStatus.CLOSED ?
            <LinkButton
              type="button"
              clickHandler={() => setJarToClose(eventData)}
              disabled={!isCloseable}
            >
              {isCloseable === false ?
                <Tooltip text="Jar cannot be closed as there are unpaid bills" className="font-normal">
                  <span className="font-bold">Close jar</span>
                </Tooltip>
              :
                <>Close Jar</>
              }
            </LinkButton>
          : null}

          {eventData.status === JarStatus.CLOSED ?
            <LinkButton type="button" clickHandler={reopenJar}>
              Re-Open
            </LinkButton>
          : null}
        </div>
      </div>

      <div className="md:col-span-2 md:row-span-4 order-2 md:order-1 space-y-1">
        {eventData.isGifting === false ? <>
          <PendingPaymentList
            event={eventData}
            contributions={pendingPayments}
            setQrToShow={setShowBillModalFor}
            userInfo={userInfo}
          />
        </>
        : null}

        <PaidPaymentsList
          event={eventData}
          contributions={paidPayments}
          showAmounts={eventData.isGifting ? displayAmounts : true}
          showMessages={eventData.isGifting ? openMessage : true}
          setBillToRepeat={(bill: Gift) => {
            if (eventData.hasEnded) return
            setBillToRepeat(bill)
            setShowCreateTxnForm(true)
          }}
        />
      </div>

      <div className="order-3">
        <ShareJar eventData={eventData} />
      </div>
    </div>
  </>
}

export default EventAdminInfo